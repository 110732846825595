@tailwind base;
@tailwind components;
@tailwind utilities;



.PullToRefresh{
    background-image: url('./Assets/icons/bg@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    backdrop-filter: brightness(0.7);
}

.S--focusing .MessageList{
    margin-top: 0px !important;
}
.Composer-input {
    overflow: auto !important;
}
.BackBottom{
    display: none;
}

@media  (max-height: 350px) {
    .Message.right .Bubble {
      font-size: 12px;
    }
    .Message.left .Bubble {
      font-size: 12px;
    }
    .Composer-input {
      font-size: 0.7rem !important;
      padding: 0.3125rem 0.75rem !important;
    }
    .Composer-actions {
      height: 1.8rem !important;
    }
    .Composer-sendBtn {
      padding-left: 0.6rem !important;
      padding-right: 0.6rem !important;
    }
    .Btn {
      font-size: 0.7rem !important;
    }
  }
  .Time{
    display: none !important;
  }.chat-bubble {
    position: absolute;
    bottom: 112px;
    background: #F5F5F5;
    border-radius: 5px 5px 0px 5px;
    padding: 10px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    width: 188px;
    right: 44px;
    transition: all 0.3s ease;
    margin-bottom: 15px;
    margin-left: 15px;
    font-weight: 600;
  }
  
  .chat-bubble::before {
    content: '';
    position: absolute;
    bottom: -14px;
    right: -1px;
    border-width: 18px 1px 3px 22px;
    border-style: solid;
    border-color: #F5F5F5 transparent transparent transparent;
    transform: rotate(-5deg);
  }
  
  .chat-bubble-1 {
    position: absolute;
    bottom: 110px;
    background: #F5F5F5;
    border-radius: 5px 5px 0px 5px;
    padding: 10px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    width: 133px;
    right: 46x;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    margin-left: 66px;
    font-weight: 600;
    transition: bottom 0.1s ease; /* Transition for moving up */
  }
  
  .chat-bubble-1.move-up {
    bottom: 172px; /* Move up when second bubble comes */
  }
  
  .chat-bubble-1::before {
    content: '';
    position: absolute;
    bottom: -12px;
    right: -1px;
    border-width: 18px 1px 3px 22px;
    border-style: solid;
    border-color: #F5F5F5 transparent transparent transparent;
    transform: rotate(-5deg);
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    position: relative; /* Ensure positioning context */
    height: 271px; /* Adjust height as needed */
  }
  
  /* CSS for individual dot */
  .dot-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #BBBBBB;
    margin: 0 3px;
    opacity: 0;
    animation: dotAnimation 1s infinite;
    -webkit-animation: dotAnimation 1.4s infinite ease-in-out both;
    animation: dotAnimation 1.4s infinite ease-in-out both;
  }
  
  .dot.dot1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .dot.dot2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  .dot.dot3 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  /* CSS keyframes for dot animation */
  @keyframes dotAnimation {
    0%, 100% {
      opacity: 0; /* Dots are hidden at start and end */
    }
    20%, 80% {
      opacity: 1; /* Dots are visible in the middle of animation */
    }
  }
  
  .close-button {
    border: none;
    font-size: 20px;
    position: absolute;
    top: 24px;
    right: 40px;
    cursor: pointer;
    color: #F5F5F5;
  }
  